export const environment = {
  production: false,
  appVersion: '0.0.1',
  host: 'https://test.makktina.com',
  appHost: 'https://testapp.makktina.com',
  apiVersion: '/api/v1',
  apiUrl: 'https://test.makktina.com/api/v1/',
  secretKey: 'A7H04OTimuhzSH2u9BLeeX2b5aLQMuLo',
  webPlatform: '5A663988-47DC-47B3-94FE-0B47F5F824A8'
};
